.grid-container
{
    display: grid;

    width:100%;
    height:100%;

    grid-template-columns: 1fr;
    grid-template-rows: 65px 1fr;

    grid-template-areas: 
        "control-panel"
        "display";
}

.grid-control-panel
{
    grid-area: control-panel;
}

.grid-display
{
    background-color:rgb(201,201,201);
    width: 100%;
    height: 100%;
    grid-area: display;
}