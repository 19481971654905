.square {
	display: flex;

	align-items: center;
	justify-content: center;

	width: 100%;
	height: 100%;

	position: relative;
}
.square-dark {
	background-color: rgb(233, 215, 180);
}
.square-light {
	background-color: rgb(177, 137, 103);
}

.square-container {
	position: absolute;

	display: flex;
	align-items: center;
	justify-content: center;

	width: 100%;
	height: 100%;

	cursor: grab;
}

.square-move {
    position: absolute;
    width: 25%;
    height: 25%;
    border-radius: 50%;
    background-color: rgba(152, 118, 88, 0.5); 
    pointer-events: none;
}

.square-hit {
    position: absolute;
    width: 85%;
    height: 85%;
    border-radius: 50%;
    border: 5px solid rgb(152, 118, 88, 0.5); /* Adjust the width and color of the ring */
    background-color: transparent; /* Ensures the inside of the ring is empty */
    opacity: 0.7; /* Adjust as needed */
    pointer-events: none;
}

/* Specific interaction statuses of the tile */
.status-selected {
	opacity: 0.5;
}
.status-hovering-empty {
	background-color: rgb(128, 187, 226);
	opacity: 0.7;
}
.status-hovering-target {
	/* keep it empty for now, no color change required */
	background-color: rgb(189, 80, 80);
	opacity: 0.7;
}
.square-image {
	height: 95%;

	pointer-events: none;
	z-index: 99;
}
