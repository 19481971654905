.projectcontainer-container
{
    display: flex;
    justify-content: center;
    align-items: center;

    width:100%;
    height:100%;
}
.projectcontainer-item
{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 45px;

    grid-template-areas: 
    "video"
    "description";

    width:100%;
    height:100%;

    overflow: hidden;
    border-radius: 3px;
}
.projectcontainer-item-video
{
    grid-area: video;
}
.projectcontainer-item-description
{
    grid-area: description;

    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
    
    /* background-color: rgb(252, 252, 252); */
    background-color: rgb(68, 68, 68);

    color: white;
    font-weight: 800;
    text-transform: uppercase;
    font-size: 12px;
}
.projectcontainer-item-logo
{
    /* Position is absolute with the first parent as 'relative' being the reference */
    /* In this case, the item description (direct parent) is the 'relative', so percentages
    used are all in regards to that parent object */
    position: absolute;

    height:30px;
    right:15px;
    top:50%;

    transform: translateY(-50%);
}