.toolbar-body
{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;    

    width: 100%;
    height: 100%;

    background-color: rgb(59, 59, 59);
}
.toolbar-item-body
{
    display: grid;

    align-items: center;

    grid-template-columns: 1fr;
    grid-template-rows: 1fr;

    grid-template-areas: 
        "toolbar-items";
}
#toolbar-items
{
    grid-area: toolbar-items;
}
#toolbar-description
{
    grid-area: toolbar-description;

    color:white;
}

.toolbar-item-group
{
    display: flex;
    justify-content: left;
    align-items: center;
    text-align: center;

    padding: 10px;
    font-weight: bold;

    width: auto;
}
.toolbar-item-text
{
    color:white;
    padding: 10px;
    font-size: 0.8rem;
}
.toolbar-item
{
    margin: 1px;
    border-radius: 2px;
    cursor: pointer;
    transition: all ease 0.2s;
}
.toolbar-item-idle
{
    background-color: rgb(90, 90, 90);
}
.toolbar-item-selected
{
    background-color: rgb(79, 127, 199);
}
.toolbar-item-idle:hover
{
    background-color: rgb(121, 121, 121);
}

.checkbox-body
{
    display: flex;
    justify-content: center;
    align-items: center;
}
.checkbox-text
{
    color: white;
    font-weight: bold;
    font-size: small;
}