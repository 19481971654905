.footer-body
{
    width:100%;
    height:100%;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: small;

    background-color: white;
}