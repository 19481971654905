.project-body
{    
    display: grid;
    height:100%;
    grid-template-columns: 280px 1fr 1fr;
    grid-template-rows: 1fr 1fr;

    grid-template-areas:
        "dropdown video video"
        "dropdown video video";

    background-color: rgb(102, 102, 102);
}
#dropdown
{
    grid-area: dropdown;
}
#video
{
    grid-area: video;
}
#sheet
{
    grid-area: sheet;
}

.preview-image
{
    width:100%;
    height:100%;
}

.project-scroll
{
    display: none;
}

@media screen and (max-width: 932px) 
{
    /* .project-body
    {    
        display: none;
    } */

    .project-scroll
    {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
}