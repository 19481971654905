.nav
{
    background: white;
    height: 100%;
    width:100%;
    display: flex;
    font-size: 1.2rem;

    border-color: rgb(175, 175, 175);
    border-style:solid;
    border-width: 0px 0px 1px 0px;   

    position: relative;
}
.nav-left
{
    height: 100%;
    width: 360px;

    display: flex;
    align-items: center;
    justify-content: center;
}
.nav-right
{
    height: 100%;
    width: calc(100vw - 360px);

    display: flex;
    align-items: center;
    justify-content: left;   
}

/* Divide the navigation bar up into multiple styles:
- Title
- Menu items
*/

.nav-title
{
    color: rgb(66, 66, 66);
    font-size: 1.5rem;
    font-weight: bold;   
}

.nav-menu
{
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 1px;
    list-style: none;
}

.nav-dropdown
{
    display: none;
}

@media screen and (max-width: 932px) {
    .nav-menu
    {
        display:none;
    }

    .line
    {
        width: 30px;
        height: 3px;
        background-color:rgb(66, 66, 66);
        margin:5px;
    }
    .hamburger
    {
        cursor:pointer;
        position:absolute;
        right:5%;
        top:50%;
        transform: translate(-5%, -50%);
    }

    .nav-dropdown
    {
        display:flex;
        flex-direction: column;

        width: 100vw;
        background-color: rgb(245, 245, 245);
        position:absolute;
        top:60px;

        z-index: 1;
        opacity: 0;
        pointer-events: none;

        transition: all 0.4s ease;
    }
    .nav-dropdown-open
    {
        opacity: 1;
        transform: translateY(0);
        pointer-events: all;
    }
    .nav-dropdown-item
    {
        height:40px;
        width:100%;
        align-items: center;
        display: flex;
        justify-content: center;

        text-decoration: none;

        cursor: pointer;
    }
    .nav-dropdown-item:hover
    {
        background-color: rgb(224, 224, 224);
    }
}

.nav-item
{
    color: rgb(66, 66, 66);
    text-decoration:none;
    padding: 0.5rem 1rem;
    font-size: 1.3rem;
}
.nav-item:hover
{
    font-weight: bold;
}