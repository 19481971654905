.outer-layer
{
    display:flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
}

.inner-layer
{
    display: flex;    
    align-items: center;
    justify-content: center;
}

.content {
    width: 95%;
    height: 95%;
}