.dropdown-item
{
    width: 100%-15px;
    height: 45px;
    
    display: flex;
    justify-content:left;
    align-items:center;

    padding-left: 15px;

    color: rgb(155, 155, 155);

    border-style:solid;
    border-width: 0px 0px 0px 4px; 
    border-color: rgb(51,51,51);

    font-size: small;

    cursor:pointer;
}
.dropdown-item-idle
{
    background-color: rgb(51, 51, 51);  
    border-color: rgb(51,51,51);
}
.dropdown-item-idle:hover
{
    background-color: rgb(66, 66 ,66);  
    border-color: rgb(66, 66 ,66);  
}
.dropdown-item-selected
{
    background-color: rgb(90, 90, 90);
    border-color: rgb(110, 176, 230);
    
    color: rgb(255, 255, 255);
}
/* No hover for selected items */
