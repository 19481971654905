.dropdown-header
{
    height: 80px;
    
    display: flex;
    justify-content:center;
    align-items:center;

    color: rgb(255, 255, 255);
    font-weight: bold;

    background-color: rgb(51, 51, 51);  

    border-color: rgb(39, 39, 39);
    border-style:solid;
    border-width: 0px 0px 1px 0px;   
}
.dropdown-logo
{
    height: 70%;
}