.cell {
  width: 100%;
  height: 100%;
  scale: 0.9;
}

@keyframes pop-in {
  from {
    transform: scale(1.3);
  }
  to {
    transform: scale(1);
  }
}

@keyframes pop-out {
  from {
    transform: scale(0.7);
  }
  to {
    transform: scale(1);
  }
}

.dark {
  background-color: rgb(17, 16, 16);

  animation-name: pop-in;
  animation-duration: 0.3s;
}

.dark:hover {
  background-color: rgb(93, 94, 95);
}

.light {
  background-color: rgb(255, 255, 255);
}

.light:hover {
  background-color: rgb(173, 204, 255);
}

.start
{
  background-color: rgb(79, 182, 101);
}
.target
{
  background-color: rgb(235, 65, 108);
}
.visited
{
  background-color: rgb(229, 65, 235);

  animation-name: pop-out;
  animation-duration: 0.3s;
}
.path
{
  background-color: rgb(62, 75, 255);

  animation-name: pop-out;
  animation-duration: 0.3s;
}
