.grid {
	display: flex;
	justify-content: center;
	align-items: center;

	width: 100%;
	height: 100%;

	flex-direction: column;

	background-color: rgb(201, 201, 201);
}

.grid-row {
	display: flex;
	align-items: center;
	justify-content: center;

	width: 100%;
	height: 5%;
}

.grid-cell {
	height: 100%;
	aspect-ratio: 1;
}
