.embed-body 
{
    width:100%;
    height:100%;
} 
  
.embed-body iframe {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}