.chess-container {
	display: grid;

	width: 100%;
	height: 100%;

	grid-template-columns: 1fr 0.5fr;
	grid-template-areas: "board moves";
}

.chess-container-board {
	grid-area: board;
}

.chess-container-moves {
	grid-area: moves;
}

.chess-board {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	border-radius: 3px;
	overflow: hidden;

	width: 100%;
	height: 100%;
}

.chess-row {
	display: flex;
	align-items: center;
	justify-content: center;

	background-color: blue;

	width: 100%;
	height: 12.5%;
}

.chess-moves {
	background-color: rgb(16, 109, 96);

	width: 100%;
	height: 100%;
}
